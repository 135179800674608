import { createAction, props } from '@ngrx/store';
import { Site, SiteStats } from '../../synaps-media.types';
import { BillingPeriod, Credit } from './sites.state';

export const loadSites = createAction(
  '[Sites] Load Sites'
);

export const loadSitesSuccess = createAction(
  '[Sites] Load Sites Success',
  props<{ sites: Site[] }>()
);

export const loadSitesFailure = createAction(
  '[Sites] Load Sites Failure',
  props<{ error: any }>()
);

export const loadSiteDetail = createAction(
  '[Sites] Load Site Detail',
  props<{ siteId: string }>()
);

export const loadSiteDetailSuccess = createAction(
  '[Sites] Load Site Detail Success',
  props<{ siteDetail: Site }>()
);

export const loadSiteDetailFailure = createAction(
  '[Sites] Load Site Detail Failure',
  props<{ error: any }>()
);

export const loadSiteStats = createAction(
  '[Sites] Load Site Stats',
  props<{ siteId: string }>()
);

export const loadSiteStatsSuccess = createAction(
  '[Sites] Load Site Stats Success',
  props<{ siteId: string, siteStats: SiteStats }>()
);

export const loadSiteStatsFailure = createAction(
  '[Sites] Load Site Stats Failure',
  props<{ error: any }>()
);
// Add these actions to state/sites/sites.actions.ts

export const loadNewsletterCredits = createAction(
  '[Newsletter Credits] Load Credits',
  props<{ serviceName: string, periodIndex?: number }>()
);

export const loadNewsletterCreditsSuccess = createAction(
  '[Newsletter Credits] Load Credits Success',
  props<{ credits: Credit[], billingPeriods: BillingPeriod[], currentPeriodIndex: number }>()
);

export const loadNewsletterCreditsFailure = createAction(
  '[Newsletter Credits] Load Credits Failure',
  props<{ error: string }>()
);

export const changeBillingPeriod = createAction(
  '[Newsletter Credits] Change Billing Period',
  props<{ periodIndex: number }>()
);

export const addCredits = createAction(
  '[Newsletter Credits] Add Credits',
  props<{ serviceName: string, creditPack: '10k' | '100k' }>()
);

export const addCreditsSuccess = createAction(
  '[Newsletter Credits] Add Credits Success',
  props<{ redirectUrl: string }>()
);

export const addCreditsFailure = createAction(
  '[Newsletter Credits] Add Credits Failure',
  props<{ error: string }>()
);
