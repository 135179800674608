import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NewsletterCreditsState, SitesState, sitesFeatureKey } from './sites.state';

export const selectSitesState = createFeatureSelector<SitesState>(sitesFeatureKey);

export const selectAllSites = createSelector(
  selectSitesState,
  (state) => state.sites
);

// Given a siteId, select the corresponding detailed info (updated by polling)
export const selectSiteDetail = (siteId: string) => createSelector(
  selectSitesState,
  (state) => state.siteDetails[siteId] || null
);

export const selectSiteStats = (siteId: string) => createSelector(
  selectSitesState,
  (state) => state.siteStats[siteId]
);

export const selectNewsletterCreditsState = createSelector(
  selectSitesState,
  (state: SitesState) => state.newsletterCredits
);

export const selectNewsletterCredits = createSelector(
  selectNewsletterCreditsState,
  (state: NewsletterCreditsState) => state.credits
);

export const selectNewsletterCreditsBillingPeriods = createSelector(
  selectNewsletterCreditsState,
  (state: NewsletterCreditsState) => state.billingPeriods
);

export const selectCurrentBillingPeriodIndex = createSelector(
  selectNewsletterCreditsState,
  (state: NewsletterCreditsState) => state.currentPeriodIndex
);

export const selectCurrentBillingPeriod = createSelector(
  selectNewsletterCreditsState,
  (state: NewsletterCreditsState) => state.billingPeriods[state.currentPeriodIndex]
);

export const selectNewsletterCreditsSummary = createSelector(
  selectNewsletterCreditsState,
  (state: NewsletterCreditsState) => state.summary?.[0]
);

export const selectNewsletterCreditsSummaryForSelectedPeriod = createSelector(
  selectNewsletterCreditsState,
  (state: NewsletterCreditsState) => state.summary?.[state.currentPeriodIndex]
);

export const selectNewsletterCreditsLoading = createSelector(
  selectNewsletterCreditsState,
  (state: NewsletterCreditsState) => state.loading
);

export const selectNewsletterCreditsError = createSelector(
  selectNewsletterCreditsState,
  (state: NewsletterCreditsState) => state.error
);
