import { Site, SiteStats } from '../../synaps-media.types';

export interface BillingPeriod {
  startDate: string;
  endDate: string;
  periodLabel: string;
}

export interface Credit {
  id: string;
  amount: number;
  remaining: number;
  expiresAt: Date;
  createdAt: Date;
  notes?: string;
  isMonthly: boolean;
}

export interface CreditSummary {
  monthly: {
    amount: number;
    used: number;
    remaining: number;
  };
  extra: {
    amount: number;
    used: number;
    remaining: number;
  };
  total: {
    amount: number;
    used: number;
    remaining: number;
  };
  range: number;
  rangeColor: string;
}

export interface NewsletterCreditsState {
  credits: Credit[];
  billingPeriods: BillingPeriod[];
  currentPeriodIndex: number;
  loading: boolean;
  error: string | null;
  summary: CreditSummary[];
}


export interface SitesState {
  sites: Site[];
  siteDetails: { [siteId: string]: Site };
  siteStats: { [siteId: string]: SiteStats };
  loading: boolean;
  error: any;
  newsletterCredits: NewsletterCreditsState;
}

export const sitesFeatureKey = 'sites';

export const initialNewsletterCreditsState: NewsletterCreditsState = {
  credits: [],
  billingPeriods: [],
  currentPeriodIndex: 0,
  loading: false,
  error: null,
  summary: null
};


export const initialState: SitesState = {
  sites: null,
  siteDetails: {},
  siteStats: {},
  loading: false,
  error: null,
  newsletterCredits: initialNewsletterCreditsState
};
